import Vue from 'vue'
import VueRouter from 'vue-router'
import checkLogin from '@/middleware/check-login'
import auth from '@/middleware/auth'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        middleware: [auth],
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        middleware: [checkLogin],
        layout: 'full',
      },
    },
    // Post manager
    {
      path: '/post-manager',
      name: 'post-manager',
      component: () => import('@/views/postManager/index.vue'),
      meta: {
        can: 'post:list' || 'post:read',
        pageTitle: 'Local Tips',
        middleware: [auth],
        breadcrumb: [
          {
            text: 'Local Tips',
            active: true,
          },
        ],
      },
    },
    {
      path: '/post-manager-modify',
      name: 'add-post',
      component: () => import('@/views/postManager/add/index.vue'),
      meta: {
        can: 'post:create',
        pageTitle: 'Add post',
        middleware: [auth],
        breadcrumb: [
          {
            text: 'Local Tips',
            active: false,
            to: '/post-manager',
          },
          {
            text: 'Add Post',
            active: true,
          },
        ],
      },
    },
    {
      path: '/post-manager-modify/:id',
      name: 'edit-post',
      component: () => import('@/views/postManager/update/index.vue'),
      meta: {
        can: 'post:update',
        pageTitle: 'Update Local Tips',
        middleware: [auth],
        breadcrumb: [
          {
            text: 'Local Tips',
            active: false,
            to: '/post-manager',
          },
          {
            text: 'Update Local Tips',
            active: true,
          },
        ],
      },
    },
    // Program manager
    {
      path: '/program-manager',
      name: 'program-manager',
      component: () => import('@/views/programManager/index.vue'),
      meta: {
        can: 'program:list' || 'program:read',
        pageTitle: 'Program',
        middleware: [auth],
        breadcrumb: [
          {
            text: 'Program',
            active: true,
          },
        ],
      },
    },
    {
      path: '/program-manager-modify',
      name: 'add-program',
      component: () => import('@/views/programManager/modify/index.vue'),
      meta: {
        can: 'program:create',
        pageTitle: 'Add program',
        middleware: [auth],
        breadcrumb: [
          {
            text: 'Program',
            active: false,
            to: '/program-manager',
          },
          {
            text: 'Add Program',
            active: true,
          },
        ],
      },
    },
    {
      path: '/program-manager-modify/:id',
      name: 'edit-program',
      component: () => import('@/views/programManager/update/index.vue'),
      meta: {
        can: 'program:update',
        pageTitle: 'Edit program',
        middleware: [auth],
        breadcrumb: [
          {
            text: 'Program',
            active: false,
            to: '/program-manager',
          },
          {
            text: 'Edit Program',
            active: true,
          },
        ],
      },
    },
    // user manager
    {
      path: '/user-manager',
      name: 'user-manager',
      component: () => import('@/views/userManager/index.vue'),
      meta: {
        can: 'account:list' || 'account:read',
        pageTitle: 'User Manager',
        middleware: [auth],
        breadcrumb: [
          {
            text: 'User Manager',
            active: true,
          },
        ],
      },
    },
    // rating config
    {
      path: '/rating-config',
      name: 'rating-config',
      component: () => import('@/views/ratingConfig/index.vue'),
      meta: {
        can: 'rating:list' || 'rating:read',
        pageTitle: 'Rating Config',
        middleware: [auth],
        breadcrumb: [
          {
            text: 'Rating Config',
            active: true,
          },
        ],
      },
    },
    {
      path: '/rating-config/add',
      name: 'add-rating-config',
      component: () => import('@/views/ratingConfig/add/index.vue'),
      meta: {
        can: 'rating:create',
        pageTitle: 'Add Rating Config',
        middleware: [auth],
        breadcrumb: [
          {
            text: 'Rating Config',
            active: false,
            to: '/rating-config',
          },
          {
            text: 'Add Rating Config',
            active: true,
          },
        ],
      },
    },
    {
      path: '/rating-config/update/:id',
      name: 'update-rating-config',
      component: () => import('@/views/ratingConfig/update/index.vue'),
      meta: {
        can: 'rating:update',
        pageTitle: 'Update Rating Config',
        middleware: [auth],
        breadcrumb: [
          {
            text: 'Rating Config',
            active: false,
            to: '/rating-config',
          },
          {
            text: 'Update Rating Config',
            active: true,
          },
        ],
      },
    },
    // program category
    {
      path: '/program-category',
      name: 'program-category',
      component: () => import('@/views/programCategoryManager/index.vue'),
      meta: {
        can: 'programCategory:list' || 'programCategory:read',
        pageTitle: 'Program Category',
        middleware: [auth],
        breadcrumb: [
          {
            text: 'Program Category',
            active: true,
          },
        ],
      },
    },
    {
      path: '/program-category/add',
      name: 'add-program-category',
      component: () => import('@/views/programCategoryManager/add/index.vue'),
      meta: {
        can: 'programCategory:create',
        pageTitle: 'Add Program Category',
        middleware: [auth],
        breadcrumb: [
          {
            text: 'Program Category',
            active: false,
            to: '/program-category',
          },
          {
            text: 'Add Program Category',
            active: true,
          },
        ],
      },
    },
    {
      path: '/program-category/update/:id',
      name: 'update-program-category',
      component: () => import('@/views/programCategoryManager/update/index.vue'),
      meta: {
        can: 'programCategory:update',
        pageTitle: 'Update Program Category',
        middleware: [auth],
        breadcrumb: [
          {
            text: 'Program Category',
            active: false,
            to: '/program-category',
          },
          {
            text: 'Update Program Category',
            active: true,
          },
        ],
      },
    },
    // post category
    {
      path: '/post-category',
      name: 'post-category',
      component: () => import('@/views/postCategoryManager/index.vue'),
      meta: {
        can: 'postCategory:list' || 'postCategory:read',
        pageTitle: 'Local Tips Category',
        middleware: [auth],
        breadcrumb: [
          {
            text: 'Local Tips Category',
            active: true,
          },
        ],
      },
    },
    {
      path: '/post-category/add',
      name: 'add-post-category',
      component: () => import('@/views/postCategoryManager/add/index.vue'),
      meta: {
        can: 'postCategory:create',
        pageTitle: 'Add Local Tips Category',
        middleware: [auth],
        breadcrumb: [
          {
            text: 'Local Tips Category',
            active: false,
            to: '/post-category',
          },
          {
            text: 'Add Local Tips Category',
            active: true,
          },
        ],
      },
    },
    {
      path: '/post-category/update/:id',
      name: 'update-post-category',
      component: () => import('@/views/postCategoryManager/update/index.vue'),
      meta: {
        can: 'postCategory:update',
        pageTitle: 'Update Local Tips Category',
        middleware: [auth],
        breadcrumb: [
          {
            text: 'Local Tips Category',
            active: false,
            to: '/post-category',
          },
          {
            text: 'Update Local Tips Category',
            active: true,
          },
        ],
      },
    },
    // general config
    {
      path: '/general-config',
      name: 'general-config',
      component: () => import('@/views/generalConfig/index.vue'),
      meta: {
        can: 'general:list' || 'general:read',
        pageTitle: 'General Config',
        middleware: [auth],
        breadcrumb: [
          {
            text: 'General Config',
            active: true,
          },
        ],
      },
    },
    {
      path: '/general-config/update/:id',
      name: 'update-general-config',
      component: () => import('@/views/generalConfig/update/index.vue'),
      meta: {
        can: 'general:update',
        pageTitle: 'Update General Config',
        middleware: [auth],
        breadcrumb: [
          {
            text: 'General Config',
            active: false,
            to: '/general-config',
          },
          {
            text: 'Update General Config',
            active: true,
          },
        ],
      },
    },
    // role manager
    {
      path: '/role-manager',
      name: 'role-manager',
      component: () => import('@/views/roleManager/index.vue'),
      meta: {
        can: 'role:read' || 'role:list',
        pageTitle: 'Role Manager',
        middleware: [auth],
        breadcrumb: [
          {
            text: 'Role Manager',
            active: true,
          },
        ],
      },
    },
    {
      path: '/role-manager/add',
      name: 'add-role',
      component: () => import('@/views/roleManager/addRbac/index.vue'),
      meta: {
        can: 'role:create',
        pageTitle: 'Add Role',
        middleware: [auth],
        breadcrumb: [
          {
            text: 'Role Manager',
            active: false,
            to: '/role-manager',
          },
          {
            text: 'Add role',
            active: true,
          },
        ],
      },
    },
    {
      path: '/role-manager/edit/:id',
      name: 'edit-role',
      component: () => import('@/views/roleManager/editRbac/index.vue'),
      meta: {
        can: 'role:update',
        pageTitle: 'Edit Role',
        middleware: [auth],
        breadcrumb: [
          {
            text: 'Role Manager',
            active: false,
            to: '/role-manager',
          },
          {
            text: 'Edit role',
            active: true,
          },
        ],
      },
    },
    // customer flow
    {
      path: '/customer-flow',
      name: 'customer-flow',
      component: () => import('@/views/customerFlow/index.vue'),
      meta: {
        can: 'customerFlow:list' || 'customerFlow:read',
        pageTitle: 'Customer',
        middleware: [auth],
        breadcrumb: [
          {
            text: 'Customer',
            active: true,
          },
        ],
      },
    },
    {
      path: '/customer-flow/add',
      name: 'add-customer-flow',
      component: () => import('@/views/customerFlow/add/index.vue'),
      meta: {
        can: 'customerFlow:create',
        pageTitle: 'Add Customer',
        middleware: [auth],
        breadcrumb: [
          {
            text: 'Customer',
            active: false,
            to: '/customer-flow',
          },
          {
            text: 'Add Customer',
            active: true,
          },
        ],
      },
    },
    {
      path: '/customer-flow/edit/:id',
      name: 'edit-customer-flow',
      component: () => import('@/views/customerFlow/update/index.vue'),
      meta: {
        can: 'customerFlow:update',
        pageTitle: 'Detail Customer',
        middleware: [auth],
        breadcrumb: [
          {
            text: 'Customer',
            active: false,
            to: '/customer-flow',
          },
          {
            text: 'Detail Customer',
            active: true,
          },
        ],
      },
    },
    {
      path: '/customer-flow/detail/:id',
      name: 'detail-customer-flow',
      component: () => import('@/views/customerFlow/detail/UsersView.vue'),
      meta: {
        can: 'customerFlow:read',
        pageTitle: 'Detail Customer',
        middleware: [auth],
        breadcrumb: [
          {
            text: 'Customer',
            active: false,
            to: '/customer-flow',
          },
          {
            text: 'Detail Customer',
            active: true,
          },
        ],
      },
    },
    // Room Service
    {
      path: '/order-manager',
      name: 'order-manager',
      component: () => import('@/views/orderManager/index.vue'),
      meta: {
        can: 'roomService:list' || 'roomService:read',
        pageTitle: 'Room Service',
        middleware: [auth],
        breadcrumb: [
          {
            text: 'Room Service',
            active: true,
          },
        ],
      },
    },
    {
      path: '/order-manager/detail/:id',
      name: 'detail-order',
      component: () => import('@/views/orderManager/update/index.vue'),
      meta: {
        can: 'roomService:read',
        pageTitle: 'Order Detail',
        middleware: [auth],
        breadcrumb: [
          {
            text: 'Room Service',
            active: false,
            to: '/order-manager',
          },
          {
            text: 'Order Detail',
            active: true,
          },
        ],
      },
    },
    // tracking user
    {
      path: '/tracking-user',
      name: 'tracking-user',
      component: () => import('@/views/userTracking/index.vue'),
      meta: {
        can: 'tracking:list',
        pageTitle: 'Tracking user',
        middleware: [auth],
        breadcrumb: [
          {
            text: 'Tracking User',
            active: true,
          },
        ],
      },
    },
    // rating user
    {
      path: '/rating-user',
      name: 'rating-user',
      component: () => import('@/views/userRating/index.vue'),
      meta: {
        can: 'rating:list' || 'rating:read',
        pageTitle: 'Rating user',
        middleware: [auth],
        breadcrumb: [
          {
            text: 'Rating User',
            active: true,
          },
        ],
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index]
  if (!subsequentMiddleware) return context.next
  return (...parameters) => {
    context.next(...parameters)
    const nextMiddleware = nextFactory(context, middleware, index + 1)
    subsequentMiddleware({
      ...context,
      next: nextMiddleware,
    })
  }
}
router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware]
    const context = {
      from,
      next,
      router,
      to,
    }
    const nextMiddleware = nextFactory(context, middleware, 1)
    return middleware[0]({
      ...context,
      next: nextMiddleware,
    })
  }
  return next()
})
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
