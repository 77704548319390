import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import authenticate from './authenticate'
import program from './program'
import posts from './posts'
import users from './users'
import rating from './rating'
import programCategory from './program-category'
import postCategory from './post-category'
import generalConfig from './general-config'
import roleManager from './roleManager'
import customerFlow from './customerFlow'
import cukcuk from './cukcuk'
import order from './order'
import statistic from './statistic'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    authenticate,
    posts,
    program,
    users,
    rating,
    programCategory,
    postCategory,
    generalConfig,
    roleManager,
    customerFlow,
    cukcuk,
    order,
    statistic,
  },
  strict: process.env.DEV,
})
